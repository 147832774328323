import {createClient} from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import type {
  LanguageCode,
  CountryCode,
} from '@shopify/hydrogen/storefront-api-types';

const sanityConfig = {
  apiVersion: 'v2022-05-01',
  dataset: 'production',
  projectId: '7m88emnx',
  useCdn: true,
};

interface Props {
  /** A string of the GROQ query. */
  query: string;
  /** An object of the variables for the GROQ query. */
  params?: Record<string, unknown>;
}

export const sanityImgBuilder = imageUrlBuilder({
  projectId: sanityConfig.projectId,
  dataset: sanityConfig.dataset,
});
export const client = createClient(sanityConfig);

export async function useSanityQuery({query, params = {}}: Props) {
  return await client.fetch(query, params);
}

export type SanityImage = {
  _type: 'image';
  asset: {
    _ref: string;
    _type: 'reference';
  };
};

export function getLangagueCodeForSanityQuery(languageCode: LanguageCode) {
  const languageCodeForSanityQuery = languageCode === 'ZH_TW' ? 'zh_TW' : 'en';
  return languageCodeForSanityQuery;
}

export function getCountryCodeForSanityQuery(countryCode: CountryCode) {
  const countryCodeForSanityQuery = countryCode === 'TW' ? 'zh-tw' : 'zh-hk';
  return countryCodeForSanityQuery;
}

export function isTWSite(url: string) {
  const urlObj = new URL(url);
  switch (urlObj.host) {
    case 'tw.helmetking.com':
      return true;
    default:
      return false;
  }
}

export function colourToClassName(
  colourName: string,
  type: 'bg' | 'text' | 'border',
) {
  if (type === 'bg') {
    if (colourName === 'Black') {
      return 'bg-black';
    }
    if (colourName === 'Dark Blue') {
      return 'bg-hk-deep-blue';
    }
    if (colourName === 'Orange') {
      return 'bg-hk-orange';
    }
    if (colourName === 'Red') {
      return 'bg-hk-red';
    }
    if (colourName === 'White') {
      return 'bg-white';
    }
  }
  if (type === 'text') {
    if (colourName === 'Black') {
      return 'text-black';
    }
    if (colourName === 'Dark Blue') {
      return 'text-hk-deep-blue';
    }
    if (colourName === 'Orange') {
      return 'text-hk-orange';
    }
    if (colourName === 'Red') {
      return 'text-hk-red';
    }
    if (colourName === 'White') {
      return 'text-white';
    }
  }
  if (type === 'border') {
    if (colourName === 'Black') {
      return 'border-black';
    }
    if (colourName === 'Dark Blue') {
      return 'border-hk-deep-blue';
    }
    if (colourName === 'Orange') {
      return 'border-hk-orange';
    }
    if (colourName === 'Red') {
      return 'border-hk-red';
    }
    if (colourName === 'White') {
      return 'border-white';
    }
  }
}

export const generateSanityInternalPath = ({
  type,
  slug,
}: {
  type: string;
  slug: string;
}) => {
  if (type === 'page') {
    return `/pages/${slug}`;
  }
  if (type === 'category') {
    return `/blogs/${slug}`;
  }
  if (type === 'collection') {
    return `/collections/${slug}`;
  }
  if (type === 'product') {
    return `/products/${slug}`;
  }
  if (type === 'home') {
    return `/`;
  }
  return '/';
};
